<template>
    <div class="is-flex ">
        <div
            v-if="lead.id"
            class="mr-2">
            <LTooltip
                type="is-info"
                :label="$t('common.statistic.detailing.depositHistory.title')">
                <b-icon
                    class="is-clickable"
                    icon="clock"
                    type="is-info"
                    size="is-small"
                    pack="uil"
                    @click.native="openModal">
                </b-icon>
            </LTooltip>
        </div>
        <div>
            {{ lead.summaryDeposit.count }}

            <LTooltip
                type="is-dark"
                :label="$t('common.statistic.detailing.table.tooltip.depositAmount')">
                {{ `(${defaultFormatter(lead.summaryDeposit.total, 2)})` }}
            </LTooltip>
        </div>
    </div>
</template>


<script>
  import { defaultFormatter } from "@core/filters";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { UPDATE_MODAL } from "@core/store/mutation-constants";

  export default {
    name: "SummaryDepositOutput",
    props: {
      lead: {
        type: Object,
        default: () => {}
      }
    },

    computed: {
      ...mapFields("detailingDepositHistoryModal", {
        fields: ["isActive", "leadId"],
        base: "modal",
        action: UPDATE_MODAL
      })
    },

    methods: {
      openModal () {
        this.isActive = true;
        this.leadId = this.lead.id;
      },
      defaultFormatter
    }
  };
</script>



<style scoped lang="scss">

</style>